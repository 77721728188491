<template>
    <v-dialog v-model="open" width="600">
        <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="info" class="mx-1">
                <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
                Manage Parts
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Manage Parts</v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="parts" item-key="part" group-by="config" hide-default-header>
                    <template #group.header="{ group, toggle, isOpen }">
                        <td class="text-start" colspan="2">
                            <v-btn small icon @click="toggle">
                                <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            </v-btn>
                            {{ group }}
                        </td>
                    </template>
                    <template #item.part="{ item }">
                        <v-btn :href="partLink(item.object)" target="_blank" color="secondary">{{ item.part }}</v-btn>
                    </template>
                    <template #item.actions="{ item }">
                        <v-progress-circular v-if="isDeleting(item)" indeterminate :size="14" :width="1" />
                        <v-icon v-else small @click="del(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as api from '@/api'

export default {
    name: 'ManageParts',
    props: {
        apiBase: String,
        token: String,
        parts: Array,
    },
    data: () => ({
        open: false,
        headers: [
            { text: 'Config', value: 'config' },
            { text: 'Part', value: 'part' },
            { text: '', value: 'actions', sortable: false, filterable: false, groupable: false },
        ],
        loading: [],
    }),
    methods: {
        async del(item) {
            if (this.loading.includes(item.part)) {
                return
            }
            this.loading.push(item.part)
            const r = await api.deletePart(this.apiBase, { id: this.token }, item)
            if (r.errorMessage != null) {
                this.$emit('error', r)
            } else {
                this.$emit('del', item)
            }
            // Wait until next tick to remove the loading indicator so the app
            // can remove the part first, preventing the icon from reverting to
            // delete before the row is removed.
            this.$nextTick(() => {
                // This is async. Don't assume the part removed is still at the
                // end of the loading list.
                this.loading = this.loading.filter((p) => p !== item.part)
            })
        },
        isDeleting(item) {
            return this.loading.includes(item.part)
        },
        partLink(object) {
            const url = new URL(`/part/${object}`, this.apiBase)
            return url.toString()
        },
    },
}
</script>