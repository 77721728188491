<template>
    <v-dialog v-model="open" width="600">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ma-2">
                <v-icon class="mr-1">mdi-view-list</v-icon>
                Select Part
            </v-btn>
        </template>
        <v-card>
            <v-card-title>Select Part</v-card-title>
            <v-card-text>
                <v-row>
                    <v-combobox v-model="selected" :items="items" :filter="filter" outlined class="mt-2">
                        <template #item="{ item, on, attrs }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content>
                                    <v-row>
                                        <v-col cols="6">
                                            {{ item.value.part }}
                                        </v-col>
                                        <v-col cols="6" align="right">
                                            {{ item.value.config }}
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" :disabled="!selected" @click="submit">Ok</v-btn>
                <v-btn color="secondary" @click="close">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PartSelect',
    props: {
        parts: Array,
    },
    data: () => ({
        open: false,
        selected: null,
    }),
    computed: {
        items: function () {
            return this.parts
                .toSorted((a, b) => a.part.localeCompare(b.part))
                .map((part) => ({
                    text: part.part,
                    value: part,
                    search: `${part.part.toUpperCase()}\x00${part.config.toUpperCase()}`
                }))
        }
    },
    methods: {
        close() {
            this.open = false
            this.selected = null
        },
        submit() {
            if (this.selected == null || this.selected === '') {
                this.close()
                return
            }
            if (typeof this.selected === 'string') {
                let s = this.parts.filter(({part}) => part.startsWith(this.selected))
                if (s.length === 0) {
                    s = this.parts.filter(({config}) => config.startsWith(this.selected))
                }
                if (s.length !== 1) {
                    this.$emit('error', this.selected)
                } else {
                    this.$emit('submit', s[0].config)
                }
            } else {
                this.$emit('submit', this.selected.value.config)
            }
            this.close()
        },
        filter(item, query) {
            const {search} = item
            return search.includes(query.toUpperCase())
        },
    },
}
</script>