<template>
    <v-dialog v-model="open" width="640">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="green" class="mx-1" @click="populateOrgs">
                <v-icon class="mr-1">mdi-account-plus</v-icon>
                Add User
            </v-btn>
        </template>
        <v-stepper v-model="step">
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card>
                        <v-card-title>Add User</v-card-title>
                        <v-card-subtitle>Select or Add Organization</v-card-subtitle>
                        <v-card-text>
                            <v-form v-model="ok">
                                <v-combobox label="Organization" v-model="org" :search-input.sync="search" :items="orgs" :loading="loading" outlined :rules="orgRules" menu-props="closeOnClick, closeOnContentClick">
                                    <template #no-data>
                                        <v-list-item>
                                            <span class="font-italic">Create {{ search }}</span>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <!-- TODO(branden): should we be able to set email even if this isn't a new org? really we need an org mgmt function. -->
                                <v-text-field v-if="newOrg" label="Organization Email" v-model="orgEmail" :rules="emailRules" hint="The system sends part creation notifications to this address." persistent-hint outlined></v-text-field>
                            </v-form>
                            <v-banner v-if="alert.open" class="w-100" color="error" single-line rounded>{{ alert.message }}</v-banner>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" @click="close">Cancel</v-btn>
                            <v-spacer />
                            <v-btn color="primary" @click="advance" :loading="loading" :disabled="!ok || loading">Next</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-card>
                        <v-card-title>Add User</v-card-title>
                        <v-card-text>
                            <v-form v-if="open" v-model="ok">
                                <!-- TODO(branden): might be nice to take default domain from org email, if we want to have the lambda return that -->
                                <v-text-field label="Email" v-model="email" type="email" outlined required :rules="emailRules"></v-text-field>
                                <v-text-field label="Organization" :value="org" outlined disabled></v-text-field>
                                <v-select v-if="canAddEmployee" label="User Type" v-model="type" :items="typeItems" outlined></v-select>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" @click="step = 1">Back</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :loading="loading" :disabled="!ok || loading" @click="submit">Add User</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>

<script>
import * as api from '@/api'

export default {
    name: "AddUser",
    props: {
        apiBase: String,
        token: String,
        canAddEmployee: Boolean,
    },
    data: () => ({
        open: false,
        step: 1,
        ok: false,
        loading: false,
        orgs: [],
        search: null,
        alert: {
            open: false,
            message: null,
        },

        orgEmail: null,
        email: null,
        org: null,
        type: '',

        emailRules: [
            value => !!value || "Email is required",
            value => /[^@\s]+@[^@.\s]+\.[^@.\s]+/.test(value) || "Invalid email",
        ],
        orgRules: [
            value => !!value || "Organization is required",
        ],
        typeItems: [
            { text: "Customer", value: "" },
            { text: "Access Employee - Can upload parts and add customer accounts", value: "e" },
            { text: "Access Manager - Can add Access Employee and Manager accounts", value: "m" },
        ],
    }),
    computed: {
        newOrg: function() { return !!this.org && !this.orgs.includes(this.org) },
    },
    watch: {
        token: function(token) {
            // If the session expires while we have the dialog open, we should
            // see it become null, so we know to close the dialog.
            if (token == null) {
                this.open = false
            }
        },
    },
    methods: {
        close() {
            this.alert.open = this.ok = this.loading = this.open = false
            this.step = 1
            this.orgEmail = this.email = this.org = null
            this.type = ''
        },
        async populateOrgs() {
            // NOTE(branden): We don't explicitly synchronize this.loading with
            // create, but populate is called only on mount, so setting loading
            // now prevents create from being called until it's done.
            this.loading = true
            const r = await api.orgsList(this.apiBase, { id: this.token })
            this.loading = false
            if (r.errorMessage != null) {
                Object.assign(this.alert, { open: true, message: `Couldn't get org list: ${r.errorMessage}` })
                return
            }
            this.orgs = r.orgs ?? []
        },
        async createOrg() {
            this.loading = true
            this.alert.open = false
            const r = await api.createOrg(this.apiBase, { id: this.token }, { org: this.org, email: this.orgEmail })
            this.loading = false
            if (r.errorMessage != null) {
                Object.assign(this.alert, { open: true, message: `Couldn't create: ${r.errorMessage}` })
                return false
            }
            // NOTE(branden): Usually we want the orgs list to be sorted so
            // that it's easy to find the org you want if you're looking by
            // dropdown. However, if you're creating a new org, then you don't
            // need to find the org in the list. So, we can just push it.
            // Except we need to use $set to make the change reactive w/e.
            this.$set(this.orgs, this.orgs.length, this.org)
            return true
        },
        async advance() {
            if (this.newOrg) {
                // TODO(branden): we shouldn't actually create the org until submit
                const ok = await this.createOrg()
                if (!ok) {
                    return
                }
            }
            this.step = 2
        },
        async submit() {
            if (!this.ok) {
                // Shouldn't be possible to call this while the form is
                // invalid, but check just in case.
                return;
            }
            this.loading = true;
            const p = {
                email: this.email,
                org: this.org,
                type: this.type,
            };
            const r = await api.createUser(this.apiBase, { id: this.token }, p);
            if (r.errorMessage == null) {
                this.$emit("success", p);
            }
            else {
                this.$emit("error", r);
            }
            this.close();
        },
    },
}
</script>