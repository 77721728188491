
/**
 * make creates a config string for an assembly.
 * @param {Object.<string, string>} asm - Assembly options.
 * @param {Object} model - The model that the assembly belongs to.
 * @returns String
 */
export function make(asm, model) {
    if (!asm || model == null) {
        return ''
    }
    return model.code.map((g) => g.map((f) => asm[f] ?? f).join('')).join(' ')
}

/**
 * parse parses a config according to a model definition.
 * If the config is falsy, the default config for the model is returned, which
 * may include nulls.
 * Parsing is lax about spaces and literal elements. Otherwise, if any part of
 * the config doesn't match the model, the returned value is null.
 * @param {string} config - The config to parse.
 * @param {Object} model - The model that the config should belong to.
 * @returns Object
 */
export function parse(config, model) {
    if (!model) {
        return null
    }
    if (!config) {
        return { ...model.defaults }
    }
    const r = {}
    for (const group of model.code) {
        for (const el of group) {
            config = config.trimStart()
            const opts = model.options[el]
            if (opts == null) {
                // Literal element. The configs we generate will always include
                // them, but we don't require them to exist in the configs we
                // parse.
                if (config.startsWith(el)) {
                    config = config.substring(el.length)
                }
                continue
            }
            // Find the longest matching option.
            const sel = opts.filter((o) => config.startsWith(o.config))
                .reduce((acc, o) => o.config.length > (acc ?? '').length ? o.config : acc, null)
            if (sel == null) {
                // There were no options matching the config.
                return null
            }
            r[el] = sel
            config = config.substring(sel.length)
        }
    }
    return r
}

/**
 * options returns the model options corresponding to the choices in asm.
 * @param {Object.<string, string>} asm - Assembly options.
 * @param {Object} model - The model the assembly belongs to.
 * @returns Object.<string, Object>
 */
export function options(asm, model) {
    return Object.fromEntries(model.optionNames.map((k) => [k, model.options[k].find((p) => p.config === asm[k])]))
}

/**
 * varies returns the record of variations applicable to an assembly.
 * @param {Object.<string, string>} asm - Assembly options.
 * @param {Object} model - The model the assembly belongs to.
 * @returns Object.<string, {config: Object.<string, string>, add: number}>
 */
export function varies(asm, model) {
    const r = {}
    const opts = options(asm, model)
    for (const opt of model.optionNames) {
        const c = opts[opt]
        // If it doesn't vary with anything, skip.
        const v = c?.varies
        if (v == null) {
            continue
        }
        // Find the list of all variations which apply to the assembly.
        const m = v.filter(({ config }) =>
            Object.entries(config).every(([k, v]) => asm[k] === v)
        )
        // If there are none, skip.
        if (m.length === 0) {
            continue
        }
        // Select the matching variation that depends on the most options.
        r[opt] = m.reduce((acc, u) => Object.keys(acc).length < Object.keys(u).length ? u : acc)
    }
    return r
}
