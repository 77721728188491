<template>
    <v-btn color="info" :loading="loading" :disabled="!ok" @click="submit">Alert Me</v-btn>
</template>

<script>
import * as api from '@/api'

export default {
    name: 'AwaitPart',

    props: {
        config: String,
        apiBase: String,
        token: String,
        disabled: Boolean,
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        ok: function () { return !(this.disabled || !this.config || !this.apiBase || !this.token) },
    },
    methods: {
        async submit() {
            this.loading = true
            if (!this.ok) {
                // Shouldn't be possible to call this while the props are
                // invalid, but check just in case.
                return
            }
            const r = await api.awaitPart(this.apiBase, { id: this.token }, { config: this.config })
            if (r.errorMessage == null) {
                this.$emit('success', { config: this.config })
            } else {
                this.$emit('error', r)
            }
            this.loading = false
        },
    }
}
</script>
