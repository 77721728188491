/**
 * maybeErr sets the status and errorMessage fields of j if they are not
 * already set.
 * @param {Response} resp - fetch response.
 * @param {Object} j - JSON body of the response.
 */
function maybeErr(resp, j) {
    j ??= {}
    j.status ??= resp.status
    if (!resp.ok) {
        j.errorMessage ??= j.message ?? resp.statusText
    }
    return j
}

/**
 * partsList gets the list of all known configurations and parts.
 * @param {(string|URL)} apiBase - URL of the API base.
 * @returns {({data: Array<{config: string, part: string, object: string}>}|{errorMessage})}
 */
export async function partsList(apiBase) {
    const url = new URL('/list', apiBase)
    try {
        const resp = await fetch(url)
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        console.error('failed to fetch parts list:', err)
        return { errorMessage: err }
    }
}

/**
 * createPart uploads a part drawing and associates the part with a config.
 * @param {(string|URL)} apiBase - URL of the API base.
 * @param {{id: string, refresh: string}} tokens - Cognito JWTs.
 * @param {{config: string, part: string, object: string, file: File}} params - Endpoint parameters.
 */
export async function createPart(apiBase, tokens, params) {
    for (const p of ['config', 'part', 'object', 'file']) {
        if (params[p] == null) {
            throw new TypeError(`params.${p} is required`)
        }
    }
    const { config, part, object, file } = params
    const sp = new URLSearchParams({ config, part, object })
    const url = new URL(`/create-part?${sp}`, apiBase)
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
            },
        })
        if (!resp.ok) {
            return maybeErr(resp, { errorMessage: resp.statusText })
        }
        const j = await resp.json()
        if (j.errorMessage != undefined) {
            return maybeErr(resp, j)
        }

        const up = await fetch(j.url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/pdf',
                'Content-Length': file.size,
            },
            body: file,
        })
        if (!up.ok) {
            return maybeErr(up, { errorMessage: up.statusText })
        }

        const assoc = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
                'Content-Type': 'application/json',
            },
        })
        const k = await assoc.json()
        return maybeErr(assoc, k)
    } catch (err) {
        return { errorMessage: err }
    }
}

export async function deletePart(apiBase, tokens, params) {
    for (const p of ['config', 'part']) {
        if (params[p] == null) {
            throw new TypeError(`params.${p} is required`)
        }
    }
    const { config, part } = params
    const sp = new URLSearchParams({ config, part })
    const url = new URL(`/delete-part?${sp}`, apiBase)
    try {
        const resp = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
            }
        })
        if (!resp.ok) {
            return maybeErr(resp, { errorMessage: resp.statusText })
        }
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        return { errorMessage: err }
    }
}

/**
 * createUser adds a user account.
 * @param {(string|URL)} apiBase - URL of the API base.
 * @param {{id: string, refresh: string}} tokens - Cognito JWTs.
 * @param {{email: string, org: string, type: string}} params - Endpoint parameters.
 */
export async function createUser(apiBase, tokens, params) {
    const pp = { ...params }
    for (const p of ['email', 'org']) {
        if (pp[p] == null) {
            throw new TypeError(`params.${p} is required`)
        }
    }
    if (!pp.type) {
        delete pp.type
    }
    const sp = new URLSearchParams(pp)
    const url = new URL(`/create-user?${sp}`, apiBase)
    try {
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
                'Content-Type': 'application/json',
            },
        })
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        return { errorMessage: err }
    }
}

/**
 * awaitPart marks an org as waiting on a part for a config if none exists.
 * @param {(string|URL)} apiBase - URL of the API base.
 * @param {{id: string, refresh: string}} tokens - Cognito JWTs.
 * @param {{config: string}} params - Endpoint parameters.
 */
export async function awaitPart(apiBase, tokens, params) {
    const { config } = params
    if (!config) {
        throw new TypeError(`params.config is required`)
    }
    const sp = new URLSearchParams(params)
    const url = new URL(`/await?${sp}`, apiBase)
    try {
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
                'Content-Type': 'application/json',
            },
        })
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        return { errorMessage: err }
    }
}

export async function orgsList(apiBase, tokens) {
    const url = new URL('/orgs', apiBase)
    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
            },
        })
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        return { errorMessage: err }
    }
}

export async function createOrg(apiBase, tokens, params) {
    const { org, email } = params
    if (!org || !email) {
        throw new TypeError('params.org and params.email are required')
    }
    const sp = new URLSearchParams({ org, email })
    const url = new URL(`/orgs?${sp}`, apiBase)
    try {
        const resp = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${tokens.id}`,
            },
        })
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        return { errorMessage: err }
    }
}

export async function sauce(apiBase, tokens) {
    const url = new URL(tokens?.id != null ? `/secret-sauce` : `/sauce`, apiBase)
    try {
        const req = { method: 'GET' }
        if (tokens?.id != null) {
            req.headers = {
                'Authorization': `Bearer ${tokens.id}`
            }
        }
        const resp = await fetch(url, req)
        const j = await resp.json()
        return maybeErr(resp, j)
    } catch (err) {
        return { errorMessage: err }
    }
}
