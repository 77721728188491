<template>
    <div class="ma-2">
        <v-btn v-for="{ part, object } of parts" :key="part" :href="partLink(object)" target="_blank" color="secondary" class="mr-1">{{ part }}</v-btn>
    </div>
</template>

<script>
export default {
    name: 'PartNumbers',
    props: {
        apiBase: String,
        parts: Array,
    },
    methods: {
        partLink(object) {
            const url = new URL(`/part/${object}`, this.apiBase)
            return url.toString()
        },
    },
}
</script>
