<template>
    <v-dialog v-model="open" width="500" @input="change">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ml-1" icon>
                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Settings
            </v-card-title>
            <v-card-text>
                <v-text-field label="API Base" v-model="apiBase"></v-text-field>
                <v-text-field label="Client ID" v-model="clientID"></v-text-field>
                <v-btn block @click="showToken = !showToken">{{ showToken ? 'Hide' : 'Show' }} Token</v-btn>
                <v-textarea v-if="showToken" label="ID Token" :value="token" readonly>{{ token }}</v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="submit" color="primary">Save</v-btn>
                <v-btn @click="close" color="secondary">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DevSettings',

    props: {
        token: String,
        value: {
            apiBase: String,
            clientID: String,
        },
    },
    data: () => ({
        open: false,
        showToken: false,

        apiBase: null,
        clientID: null,
    }),
    methods: {
        close() {
            this.open = false
        },
        submit() {
            this.$emit('input', { apiBase: this.apiBase, clientID: this.clientID })
            this.close()
        },
        change(open) {
            if (!open || this.value == null) {
                return
            }
            const { apiBase, clientID } = this.value
            this.apiBase = apiBase
            this.clientID = clientID
        },
    },
}
</script>
