<script>
import PartNumbers from '@/components/PartNumbers.vue'

export default {
	name: 'ChildComp',
	components: {
		PartNumbers,
	},
	props: {
		apiBase: String,
		config: String,
		parts: Array,
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		matching: function () {
			return this.parts
				.filter(({ config }) => config == this.config)
		},
		haveParts: function () { return this.matching.length > 0 },
		msg: function () {
			switch (true) {
				case this.loading: return "Loading part numbers..."
				case this.parts.length === 0: return "An error occurred while loading the configurations."
				case !this.haveParts: return "No parts available for this configuration."
				default: return `Part numbers:`
			}
		},
	},
}
</script>

<template>
	<div>
		<v-row class="d-flex justify-center">
			<h2>{{ msg }}</h2>
		</v-row>
		<v-row v-if="haveParts">
			<PartNumbers :parts="matching" :api-base="apiBase" />
		</v-row>
	</div>
</template>
