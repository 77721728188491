<template>
    <v-dialog v-model="open" width="500">
        <template #activator="{ on, attrs }">
            <v-btn color="info" v-bind="attrs" v-on="on" :disabled="disabled">Upload Part</v-btn>
        </template>
        <v-card>
            <v-card-title>Add Part</v-card-title>
            <v-card-text>
                <v-form v-model="ok">
                    <v-text-field label="Configuration" name="config" :value="config" readonly filled required>{{ config }}</v-text-field>
                    <v-file-input label="Drawing" v-model="file" show-size accept=".pdf" required :rules="validFile" @change="fileChanged"></v-file-input>
                    <v-text-field label="Part Number" v-model="part" required :rules="validPart"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="!ok || loading" :loading="loading" @click="submit">Create</v-btn>
                <v-btn color="secondary" @click="close">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as api from '@/api'

export default {
    name: 'AddPart',

    props: {
        config: String,
        apiBase: String,
        token: String,
        disabled: Boolean,
    },
    data: () => ({
        open: false,
        file: null,
        part: null,
        ok: false,
        loading: false,

        validFile: [
            value => !!value || 'Drawing must be provided',
            value => value?.size < 6e6 || 'File must be smaller than 6 MB',
        ],
        validPart: [
            value => !!value || 'Part number must be provided'
        ],
    }),
    computed: {
        basename: function () {
            if (!this.file?.name) {
                return
            }
            const name = this.file.name
            const k = name.lastIndexOf('.')
            if (k > 0) {
                return name.substring(0, k)
            }
            return name
        }
    },
    methods: {
        fileChanged(evt) {
            console.log(this.config, this.file, evt)
            if (evt == null) {
                return
            }
            if (this.part) {
                // A part number is already set. Don't change it.
                return
            }
            this.part = this.basename
        },
        close() {
            this.loading = this.open = false
            this.file = this.part = null
        },
        async submit() {
            if (!this.ok) {
                // Shouldn't be possible to call this while the form is
                // invalid, but check just in case.
                return
            }
            this.loading = true
            const p = {
                config: this.config,
                part: this.part,
                object: this.file.name,
                file: this.file,
            }
            const r = await api.createPart(this.apiBase, { id: this.token }, p)
            if (r.errorMessage == null) {
                this.$emit('success', p)
            } else {
                this.$emit('error', r)
            }
            console.log(r)
            this.close()
        },
    },
}
</script>