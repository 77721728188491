<script>
import * as assembly from '@/assembly'

export default {
    name: 'ConfiguratorMain',

    props: {
        sauce: Object,
        value: String,
    },
    data: () => ({
        assembly: {},
    }),
    computed: {
        unset: function () { return this.sauce.optionNames.flatMap((k) => this.assembly[k] == null ? [k] : []) },
        code: function () { return this.unset.length === 0 ? assembly.make(this.assembly, this.sauce) : null },
        options: function () { return assembly.options(this.assembly, this.sauce) },
        varies: function () { return assembly.varies(this.assembly, this.sauce) },
        basePrice: function () {
            if (this.unset.length !== 0) {
                return 0 / 0
            }
            return Object.values(this.options).reduce((acc, { price }) => price != null ? acc + price : null, null)
        },
        pricetag: function () {
            if (this.basePrice === null) {
                return null
            }
            return this.basePrice + Object.values(this.varies).reduce((acc, { add }) => acc + add, 0)
        },
        message: function () {
            if (this.unset.length > 0) {
                return `Please select ${this.unset.join(', ')}`
            }
            if (this.pricetag === null) {
                return `Built Configuration: ${this.code}`
            }
            return `Built Configuration: ${this.code} for $${this.pricetag}`
        },
    },
    watch: {
        sauce: function () {
            this.clear()
        },
        value: function (v) {
            if (v == null) {
                this.clear()
                return
            }
            this.$set(this, 'assembly', assembly.parse(this.value, this.sauce))
        },
        code: function (v) {
            this.$emit('input', v)
        },
    },
    methods: {
        clear() {
            this.$set(this, 'assembly', { ...this.sauce.defaults })
        },
        varyParts(sauce, option, item) {
            const r = new Set()
            // Get the configs that this item varies with.
            if (item.varies != null) {
                for (const { config } of item.varies) {
                    for (const k of Object.keys(config)) {
                        r.add(k)
                    }
                }
            }
            // Add all options that vary with this item.
            for (const k of sauce.optionNames) {
                if (k === option) {
                    // Options can't vary with themselves. Skip.
                    continue
                }
                const m = sauce.options[k].some(({ varies }) => varies?.some(({ config }) => config?.[option] === item.config))
                if (m) {
                    r.add(k)
                }
            }
            const a = new Array(...r.values())
            return a.sort((a, b) => sauce.optionNames.indexOf(a) - sauce.optionNames.indexOf(b)).join(", ")
        },
        // modelChanged(evt) {
        //     const url = new URL(document.location)
        //     const search = new URLSearchParams(url.search)
        //     search.set('model', evt)
        //     url.search = search
        //     history.replaceState({}, '', url)
        // },
    },
    mounted() {
        if (this.value == null) {
            this.clear()
        }
    },
    // created() {
    // // Check for starting model in the URL.
    // const search = new URLSearchParams(document.location.search)
    // const model = search.get('model')
    // if (model != null && model in this.sauce) {
    //     this.selectedModel = model
    // }
    // },
}
</script>

<template>
    <v-container class="d-block">
        <v-row class="d-flex flex-column align-center">
            <h3>{{ message }}</h3>
        </v-row>
        <v-row v-for="option in sauce.optionNames" :key="option" class="d-flex flex-grow-1 w-100">
            <v-select :items="sauce.options[option]" v-model="assembly[option]" :label="option" item-text="desc" item-value="config" outlined>
                <template #item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    <v-chip small class="mr-4" color="primary">{{ item.config }}</v-chip>
                                    <span>{{ item.desc }}</span>
                                    <v-spacer />
                                    <v-chip v-if="item.price != null" small color="secondary">${{ item.price }}</v-chip>
                                    <v-chip v-if="varyParts(sauce, option, item)" class="ml-4">Price varies with {{ varyParts(sauce, option, item) }}</v-chip>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template #selection="{item, parent}">
                    <v-row align="center" no-gutters>
                        <v-chip small class="mr-4" color="primary" @hook:mounted="parent.$refs.input.style='display: none;'">{{ item.config }}</v-chip>
                        <span>{{ item.desc }}</span>
                        <v-spacer />
                        <v-chip v-if="item.price != null" small color="secondary">${{ item.price }}</v-chip>
                    </v-row>
                </template>
            </v-select>
        </v-row>
    </v-container>
</template>
